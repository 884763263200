<template>
  <div>
    <v-row dense>
      <v-col cols="2">
        <select-pos-model
          v-model="idPos"
          :label="$t('labels.pos')"
          @change="onPosChange"
        ></select-pos-model>
      </v-col>

      <v-col cols="2">
        <select-employee-model
          v-model="id_employee"
          :id-pos="idPos"
          :label="$t('labels.employee')"
        ></select-employee-model>
      </v-col>

      <v-col cols="5">
        <input-qr-scan-model
          ref="inputQr"
          v-model="code"
          label="Quét UID | IMEI"
          @keyupEnter="inputScan"
          :disabled="!idPos"
        ></input-qr-scan-model>
      </v-col>

      <v-col cols="3">
        <v-row dense>
          <v-col cols="6">
            <v-btn
              color="error"
              block
              :disabled="items.length === 0 || !idPos"
              @click="cancel"
            >
              {{ $t("labels.cancel") }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="success"
              block
              :disabled="items.length === 0 || !idPos"
              @click="finishExport"
            >
              {{ $t("labels.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-simple-table class="table-padding-2">
          <template v-slot:default>
            <thead class="v-data-table-header">
              <tr>
                <th class="text-center">Hình ảnh</th>
                <th class="text-center">IMEI | UID</th>
                <th class="text-center">QC</th>
                <th class="text-center">Barcode</th>
                <th class="text-center">Tên hàng</th>
                <th class="text-center">Đặc tả</th>
                <th class="text-center">Size</th>
                <th class="text-center" style="min-width: 150px">Ghi chú</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="text-center"
                v-for="(item, index) in items"
                :key="`i_${index}_${item.id}`"
              >
                <td>
                  <ImageViewer
                    width="60px"
                    height="auto"
                    v-if="item.url_images"
                    :url="item.url_images"
                  />
                </td>
                <td>{{ item.sku_uid }}</td>
                <td>
                  <span
                    :class="lastActionColor[item.last_action]"
                    class="font-weight-medium"
                  >
                    {{ $t(`labels.borrow_last_action_${item.last_action}`) }}
                  </span>
                </td>
                <td>{{ item.customer_goods_barcode }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.description }}</td>
                <td>{{ item.size }}</td>
                <td>
                  <v-text-field
                    v-model="item.note"
                    class="c-input-xs"
                    dense
                    outlined
                    hide-details
                    single-line
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "BorrowReturnAction",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    type: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    idPos: null,
    code: null,
    isLoading: false,
    id_employee: null,
    items: [],
    lastActionColor: {
      0: "purple--text",
      1: "primary--text",
      2: "error--text",
    },
  }),
  computed: {},
  watch: {},
  mounted() {
    /* if (
      window.me &&
      window.me.working &&
      window.me.working[`goods_${this.type}`] &&
      window.me.working[`goods_${this.type}_item`]
    ) {
      const working = JSON.parse(window.me.working[`goods_${this.type}_item`]);
      this.id_employee = working.id_employee;
      this.getProcessing();
    } */
  },
  methods: {
    cancel() {
      this.items = [];
    },
    async inputScan() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/get-item-borrow", {
          code: this.code,
          id_pos: this.idPos,
          id_employee: this.id_employee,
        });

        this.code = null;
        this.isLoading = false;

        this.items.unshift(data);
        this.$root.$emit("playSuccessAudio");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    async getProcessing() {
      const { data } = await httpClient.post(
        "/get-processing-goods-borrow-return"
      );
      if (data && data.length > 0) {
        this.items = [...data];
      }
    },
    async finishExport() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/finish-goods-borrow-return", {
          items: this.items,
          id_pos: this.idPos,
          id_employee: this.id_employee,
        });

        this.isLoading = false;
        this.$root.$emit("playSuccessAudio");
        this.resetValue();

        /* this.updateWorking({
          type: `goods_${this.type}`,
          item: null,
        }); */
        this.downloadExcelFile(
          "/goods-borrow-return-excel",
          {
            id: data,
            type_to_text: {
              1: "TRẢ NHÀ CUNG CẤP",
              2: "HỦY SẢN PHẨM",
              3: "SỬA CHỮA",
              4: "BÁN THANH LÝ",
            },
          },
          "A4_ban_giao_hang_hoa.xlsx"
        );
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.code = null;
        this.isLoading = false;
        this.$root.$emit("playErrorAudio");
      }
    },
    onPosChange() {
      this.focusInputQr();
    },
    focusInputQr() {
      setTimeout(() => {
        this.$refs.inputQr.focusInput();
      }, 200);
    },
    resetValue() {
      this.id_employee = null;
      this.idPos = null;
      this.items = [];
    },
    nextStep() {
      /* this.updateWorking({
        type: `goods_${this.type}`,
        item: JSON.stringify({
          id_employee: this.id_employee,
        }),
      }); */
    },
  },
};
</script>

<style scoped></style>
